import React from "react";
import {observer} from "mobx-react";
import {Button, Col, Form, Modal, Row, Select} from "antd";
import {coreClientInstance} from "../../services/api/coreClient";
import {CancellationReason} from "../../services/api/CoreApiClient";
import {EnumFriendlyMapper} from "../../services/utils/EnumFriendlyMapper";
import {observable} from "mobx";

export interface IDrivingCancellationTarget {
    id: string,
    dateTime: string
    optLockDateTime: string
}

interface IDrivingCancellationModalProps {
    visible: boolean;
    driving: IDrivingCancellationTarget
    onDrivingCanceled: () => any
    closeModal: () => any
}

@observer
export class DrivingCancellationModal extends React.Component<IDrivingCancellationModalProps> {

    componentDidUpdate(prevProps: Readonly<IDrivingCancellationModalProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.visible !== this.props.visible || prevProps.driving?.id !== this.props.driving?.id) {
            this.reason = CancellationReason.StudentTimeOff
        }
    }

    @observable reason: CancellationReason = CancellationReason.StudentTimeOff
    @observable cancellation = false

    cancelDriving = async () => {
        this.cancellation = true
        try {
            await coreClientInstance.schedule.cancelDrivingSessionByIdCreate({
                id: this.props.driving.id,
                optLockDateTime: this.props.driving.optLockDateTime,
                cancellationReason: this.reason
            })
            this.props.onDrivingCanceled()
        } finally {
            this.cancellation = false
        }
    }
    closeModal = async () => {
        this.props.closeModal()
    }

    render() {
        return <Modal key={`${this.props.visible}`}
                      visible={this.props.visible}
                      onCancel={this.closeModal}
                      footer={<Row justify={'end'} gutter={15}>
                          <Col><Button onClick={this.closeModal}>Назад</Button></Col>
                          <Col><Button loading={this.cancellation} type={'primary'} onClick={this.cancelDriving}>Отменить</Button></Col>
                      </Row>}
                      title={'Отменить занятие'}>
            <Form.Item label={'Причина отмены'} labelCol={{span: 24}}>
                <Select value={this.reason} onChange={value => this.reason = value} showSearch={true}
                        optionFilterProp={'children'}>
                    {Object.values(CancellationReason).map(reason => <Select.Option key={reason}
                                                                                    value={reason}>{EnumFriendlyMapper.mapCancellationReason(reason)}</Select.Option>)}
                </Select>
            </Form.Item>
        </Modal>;
    }
}
