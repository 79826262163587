import React, {CSSProperties} from "react";
import {Calendar, EventPropGetter, momentLocalizer, View} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css"
import {computed, observable} from "mobx";
import moment from "moment-timezone";
import {SessionActionType, SessionCategory, StudentCalendarDto, StudentSessionInWorkTimeDto} from "../../services/api/CoreApiClient";
import {coreClientInstance} from "../../services/api/coreClient";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";
import {DrivingSessionDetailsScene} from "../../scenes/authorized/Sessions/DrivingSessionDetailsScene";
import {SpinOnCenter} from "../Loading/SpinOnCenter";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import SessionConfirmModal from "../../scenes/authorized/Students/components/SessionConfirmModal";
import SessionSetBookingModal from "../../scenes/authorized/Students/components/SessionSetBookingModal";
import SessionResetExamModal from "../../scenes/authorized/Students/components/SessionResetExamModal";
import SessionConfirmTimeOrResetModal from "../../scenes/authorized/Students/components/SessionConfirmTimeOrResetModal";

const calendarLocalizer = momentLocalizer(moment)

interface StudentScheduleProps extends RouteComponentProps {
    studentUserId: string
}


type ItemType = 'burnedTime' | 'busyTime' | 'examTime'

interface BookingEvent {
    id: string;
    type: ItemType;
    title: string;
    start: Date;
    end: Date;
    resourceId: string;
    categoryId: string | undefined;
    canRouteToSessionDetails: boolean;
    isPastTime: boolean;
    actionType?: SessionActionType;
    bookingId?: string;
    confirmed?: boolean;
    studentAccepted?: boolean
    bookedTime?: string
}

@observer
export class StudentSchedule extends React.Component<StudentScheduleProps> {

    async componentDidMount() {
        await this.load()
    }

    load = async () => {
        try {
            this.calendarLoading = true
            this.setDateRangeToState()
            this.calendar = (await coreClientInstance.schedule.getStudentCalendarList({
                StudentUserId: this.props.studentUserId,
                UseTimeZone: dateTimeHelper.toBackendTimeZone(),
                FromDate: dateTimeHelper.toBackendDate(this.dateRange![0]),
                ToDate: dateTimeHelper.toBackendDate(this.dateRange![1]),
            })).data
        } finally {
            this.calendarLoading = false
        }
    }

    @computed get resource() {
        return [{resourceId: this.props.studentUserId, resourceName: this.props.studentUserId}]
    }

    @observable calendarLoading = false
    @observable calendar?: StudentCalendarDto

    @computed get dataSource() {
        const now = moment()

        const driving: BookingEvent[] = this.calendar?.workTimes
            ?.filter(workTime => !!workTime.driving && workTime.driving.category === SessionCategory.Practice)
            ?.map(workTime => ({
                id: workTime.driving!.id!,
                resourceId: this.props.studentUserId,
                start: dateTimeHelper.fromBackendDateTime(workTime.start!)!.toDate()!,
                end: dateTimeHelper.fromBackendDateTime(workTime.end!)!.toDate(),
                title: `${workTime.driving!.sessionName}`,
                type: workTime.driving?.isCanceled ? "burnedTime" : "busyTime",
                isPastTime: moment(workTime.start).diff(now) < 0,
                categoryId: workTime.drivingCategory?.id,
                canRouteToSessionDetails: true
            })) ?? [] as BookingEvent[]

        const exams: BookingEvent[] = this.calendar?.workTimes
            ?.filter(workTime => !!workTime.driving && (workTime.driving.category === SessionCategory.Offset || workTime.driving.category === SessionCategory.Exam))
            ?.map(workTime => ({
                id: workTime.driving!.id!,
                resourceId: this.props.studentUserId,
                start: dateTimeHelper.fromBackendDateTime(workTime.start!)!.toDate()!,
                end: dateTimeHelper.fromBackendDateTime(workTime.end!)!.toDate(),
                title: `${(workTime.driving?.sessionName ? workTime.driving?.sessionName : "") 
                    + (this.handleMapText(workTime.driving!))}`,
                type: "examTime",
                isPastTime: moment(workTime.start).diff(now) < 0,
                categoryId: workTime.drivingCategory?.id,
                canRouteToSessionDetails: false,
                bookingId: workTime.driving?.bookingId,
                confirmed: workTime.driving?.confirmed,
                studentAccepted: workTime.driving?.studentAccepted,
                actionType: workTime.driving?.actionType,
                bookedTime: workTime.driving?.bookedTime
                
            })) ?? [] as BookingEvent[]

        return driving.concat(exams)
    }

    @computed get startTime() {
        const min = this.dataSource
            .map(event => moment(event.start))
            .reduce((previous, current) => current.hours() < previous.hours() ? current : previous,
                moment())
        if (min.creationData().input)
            return new Date(min.creationData().input!.toString())
        else
            return min.startOf('day').toDate();
    }

    @computed get endTime() {
        const max = this.dataSource
            .map(event => moment(event.end))
            .reduce((previous, current) => (current.hours() === 0 ? current.subtract(1, 'seconds').hours() : current.hours()) > previous.hours() ? current : previous,
                moment().startOf('day'))
        return max.toDate()
    }

    handleMapText = (driving: StudentSessionInWorkTimeDto) => {
        if (driving.actionType === SessionActionType.None)
            return " (подтверждение не требуется)";
        if (driving.actionType === SessionActionType.ExamConfirmation && driving.confirmed === true)
            return " (занятие подтверждено)"
        if (driving.actionType === SessionActionType.BookingAnExam && !!driving.bookingId)
            return " (ученик записан)"
        if (driving.actionType === SessionActionType.DoubleСonfirmation && driving.bookingId && driving.studentAccepted === false)
            return " (требуется подтвердить время)"
        if (driving.actionType === SessionActionType.DoubleСonfirmation && !driving.bookingId && driving.confirmed === true)
            return " (время экзамена не установлено)"
        if (driving.actionType === SessionActionType.DoubleСonfirmation && driving.bookingId && driving.studentAccepted === true)
            return " (занятие подтверждено)"
        return ""
    }

    handleCheckApprovedExam = (event: BookingEvent) => {
        const approved = "rgb(164,164,164)"
        const notApproved = "rgb(129,129,129)"
        if (event.actionType === SessionActionType.None)
            return approved
        if ((event.confirmed || event.studentAccepted === true) && event.actionType !== SessionActionType.DoubleСonfirmation)
            return approved
        if (event.confirmed && event.studentAccepted === true && event.actionType === SessionActionType.DoubleСonfirmation)
            return approved
        return notApproved

    }

    eventPropGetter: EventPropGetter<BookingEvent> = (event, start, end, isSelected) => {
        const commonStyle: CSSProperties = {}

        switch (event.type) {
            case 'busyTime':
                return {
                    style: {
                        ...commonStyle,
                        cursor: !event.canRouteToSessionDetails ? 'default' : undefined,
                        backgroundColor: "rgb(164,164,164)",
                        borderColor: "rgb(255,255,255)",
                    } as CSSProperties
                }
            case 'burnedTime':
                return {
                    style: {
                        ...commonStyle,
                        cursor: 'default',
                        color: 'rgb(153, 151, 150)',
                        backgroundColor: "#fff",
                        borderColor: "rgb(153, 151, 150)"
                    } as CSSProperties
                }
            case 'examTime':
                return {
                    style: {
                        ...commonStyle,
                        backgroundColor: this.handleCheckApprovedExam(event),
                        cursor: 'pointer',
                        color: undefined,
                        borderColor: "rgb(255,255,255)",
                    } as CSSProperties
                }
        }
    }

    @observable selectedEvent?: BookingEvent = undefined
    @observable confirmModalVisible: boolean = false;
    @observable bookingModalVisible: boolean = false;
    @observable resetExamModalVisible: boolean = false;
    @observable doubleExamModalVisible: boolean = false;

    onSelectEvent: ((event: BookingEvent, e: React.SyntheticEvent<HTMLElement>) => void) = async (event) => { //add click on exam
        console.log(event);
        if (event.type === 'busyTime' && event.canRouteToSessionDetails) {
            this.props.history.push(DrivingSessionDetailsScene.route.pathFormat(event.id))
            return;
        }
        if (event.type === "examTime" && event.actionType === SessionActionType.ExamConfirmation && !event.confirmed)
        {
            this.confirmModalVisible = true;
            this.selectedEvent = event;
            return;
        }
        if (event.type === "examTime" && event.actionType === SessionActionType.BookingAnExam && !event.bookingId)
        {
            this.bookingModalVisible = true;
            this.selectedEvent = event;
            return;
        }
        if (event.type === "examTime" && event.actionType === SessionActionType.DoubleСonfirmation && event.studentAccepted === false && event.bookedTime)
        {
            this.doubleExamModalVisible = true;
            this.selectedEvent = event;
        }
        if (event.type === 'examTime' && (event.confirmed || event.bookingId) && (event.actionType !== SessionActionType.DoubleСonfirmation)){
            this.resetExamModalVisible = true;
            this.selectedEvent = event;
            return;
        }
        if (event.type === "examTime" && event.actionType === SessionActionType.DoubleСonfirmation && !event.confirmed)
        {
            this.confirmModalVisible = true;
            this.selectedEvent = event;
            return;
        }
        if (event.type === 'examTime' && event.confirmed && event.actionType === SessionActionType.DoubleСonfirmation && (event.studentAccepted === true || !event.bookedTime)){
            this.resetExamModalVisible = true;
            this.selectedEvent = event;
            return;
        }
    }

    onCloseModal = () => {
        this.selectedEvent = undefined
        this.confirmModalVisible = false;
        this.bookingModalVisible = false;
        this.resetExamModalVisible = false;
    }
    
    onResetTime = () => {
        console.log("reset")
        this.doubleExamModalVisible = false;
        this.resetExamModalVisible = true;
    }

    onCloseModalWithLoad = () => {
        this.onCloseModal()
        this.load();
    }

    @observable view: View = 'week'
    onView: ((view: View) => void) = async view => {
        this.view = view
        await this.load()
    }

    // @ts-ignore
    dateRange: [moment.Moment, moment.Moment]
    // @ts-ignore
    calendarDate: Date = moment().toDate()

    changeCalendarDate = async (newDate: Date) => {
        this.calendarDate = newDate
        await this.load()
    }
    setDateRangeToState = () => {
        switch (this.view) {
            case "month":
                this.dateRange = [moment(this.calendarDate).startOf('month').subtract(7, 'days'), moment(this.calendarDate).endOf('month').add(7, 'days')]
                break;
            case "week":
            case "work_week":
                this.dateRange = [moment(this.calendarDate).startOf('week'), moment(this.calendarDate).endOf('week')]
                break;
            case "day":
                this.dateRange = [moment(this.calendarDate).startOf('day'), moment(this.calendarDate).endOf('day')]
                break;
            case "agenda":
                this.dateRange = [moment(this.calendarDate).startOf('day'), moment(this.calendarDate).endOf('day').add(1, 'month')]
                break;
        }
    }
    render() {
        return <React.Fragment>
            <div>
                {this.selectedEvent ? 
                    <>
                        <SessionConfirmModal 
                            visible={this.confirmModalVisible}
                            sessionId={this.selectedEvent.id} 
                            studentId={this.props.studentUserId} 
                            onCanceled={this.onCloseModal}
                            onConfirmed={this.onCloseModalWithLoad}
                        />
                        <SessionSetBookingModal
                            visible={this.bookingModalVisible}
                            sessionId={this.selectedEvent.id} 
                            studentId={this.props.studentUserId} 
                            onCanceled={this.onCloseModal}
                            onConfirmed={this.onCloseModalWithLoad}
                        />
                        <SessionResetExamModal
                            visible={this.resetExamModalVisible}
                            sessionId={this.selectedEvent.id} 
                            studentId={this.props.studentUserId} 
                            entryId={this.selectedEvent.bookingId ? this.selectedEvent.bookingId : undefined}
                            onCanceled={this.onCloseModal}
                            onConfirmed={this.onCloseModalWithLoad}
                        />
                        <SessionConfirmTimeOrResetModal 
                            visible={this.doubleExamModalVisible}
                            studentId={this.props.studentUserId}
                            entryId={this.selectedEvent.bookingId!}
                            sessionId={this.selectedEvent.id}
                            bookedTime={this.selectedEvent.bookedTime!}
                            onCanceled={this.onCloseModal}
                            onConfirmed={this.onCloseModalWithLoad}
                            onResetTime={this.onResetTime}
                        />
                    </> : null}
                {this.calendarLoading
                    ? <SpinOnCenter/>
                    : <Calendar
                        style={{height: '100%', width: '100%'}}
                        events={this.dataSource}
                        views={{
                            week: true,
                            day: true
                        }}

                        resources={this.resource}
                        resourceIdAccessor={resource => resource.resourceId}
                        resourceTitleAccessor={resource => resource.resourceName}
                        view={this.view}
                        onView={this.onView}
                        date={this.calendarDate}
                        onNavigate={this.changeCalendarDate}
                        defaultDate={this.calendarDate}
                        showMultiDayTimes
                        eventPropGetter={this.eventPropGetter}
                        onSelectEvent={this.onSelectEvent}
                        localizer={calendarLocalizer}
                        min={this.startTime}
                        max={this.endTime}
                        formats={{
                            eventTimeRangeFormat: (range, culture, localizer) => moment(range.start).format("HH:mm")
                        }}
                        messages={{
                            week: 'Неделя',
                            day: 'День',
                            today: 'Сегодня',
                            previous: 'Назад',
                            next: 'Вперед'
                        }}
                    />
                }
            </div>
        </React.Fragment>
    }
}
